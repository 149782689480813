import { Outlet } from '@tanstack/react-location';
import { theme } from '@frontend/theme';
import { Nav } from './components/nav';
import { PageHeader } from './components/page-header';

export const App = () => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <PageHeader />
      <main css={{ background: theme.colors.neutral5, height: '100%', overflow: 'auto' }}>
        <Outlet />
      </main>
      <footer css={{ borderTop: `1px solid ${theme.colors.neutral20}` }}>
        <Nav />
      </footer>
    </div>
  );
};
