import { PersonHelpers } from '@frontend/api-person';
import { FirstWordBoldText } from '@frontend/grid-dashboard';
import { theme } from '@frontend/theme';
import { Avatar, Text } from '@frontend/design-system';

export const PageHeader = () => {
  const user = {
    username: 'adrian.foong@getweave.com',
    firstName: 'Nadya',
    lastName: 'Maryam',
    mobileNumber: '123-123-1234',
    gender: 'Male',
    type: 'weave',
    userID: '820db53f-74f5-4bf8-a3f9-05c196d6c8de',
    birthDate: '7/16/1989',
  } as const;

  return (
    <header
      css={{
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.colors.neutral10}`,
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
        <Avatar size='xl' name={PersonHelpers.getFullName({ FirstName: user.firstName, LastName: user.lastName })} />
        <div>
          <FirstWordBoldText as='h2' css={{ fontSize: theme.fontSize(36), lineHeight: 1 }}>
            {PersonHelpers.getFullName({ FirstName: user.firstName, LastName: user.lastName })}
          </FirstWordBoldText>
          <div css={{ display: 'flex', gap: theme.spacing(1) }}>
            <Text as='span' color='light'>
              {user.gender}
            </Text>
            <Text as='span' color='light'>
              {PersonHelpers.getAge({ Birthdate: user.birthDate })} yrs
            </Text>
          </div>
        </div>
      </div>
    </header>
  );
};
