import { css } from '@emotion/react';
import { Link, useLocation } from '@tanstack/react-location';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, NakedUl } from '@frontend/design-system';

const navItems = [
  { name: 'Home', to: '/home', Icon: <Icon name='home' /> },
  { name: 'Appointments', to: '/appointments', Icon: <Icon name='calendar' /> },
  { name: 'Payments', to: '/payments', Icon: <Icon name='payments' /> },
  { name: 'Profile', to: '/profile', Icon: <Icon name='user' /> },
];

export const Nav = () => {
  const { current } = useLocation();
  return (
    <nav>
      <NakedUl
        css={css`
          display: flex;
          height: 100%;
          justify-content: space-evenly;
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
          a {
            color: unset;
            text-decoration: unset;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        `}
      >
        {navItems.map((item, key) => {
          const isSelected = item.to === current.pathname;
          const iconColor = isSelected ? theme.colors.primary50 : theme.colors.neutral90;
          return (
            <li
              key={key}
              css={css`
                svg {
                  fill: ${iconColor};
                  color: ${iconColor};
                }
              `}
            >
              <Link to={item.to} css={{ padding: theme.spacing(2) }}>
                {item.Icon}
                <Text size='medium' color={isSelected ? 'primary' : 'default'}>
                  {item.name}
                </Text>
              </Link>
            </li>
          );
        })}
      </NakedUl>
    </nav>
  );
};
