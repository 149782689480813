import { useNavigate } from '@tanstack/react-location';
import { useMutation } from 'react-query';
import { theme } from '@frontend/theme';
import { TextField, Button, useFormField, FormRow, Text } from '@frontend/design-system';
import { useIsAuthedShallowStore } from '../../../is-authed';

const environmentVariables = {
  baseApiUrl: 'https://api.weaveconnect.com',
};

const urls = {
  getOtp: `${environmentVariables.baseApiUrl}/forms-digital/v1/prepopulation/otp`,
  verifyOtp: `${environmentVariables.baseApiUrl}/forms-digital/v1`,
};

export default function SignIn() {
  const { signIn } = useIsAuthedShallowStore('signIn');
  const navigate = useNavigate();
  const fieldProps = useFormField({
    type: 'text',
  });
  const queryParams = new URLSearchParams(window.location.search);
  const companyId = queryParams.get('company_id');
  const qwe = queryParams.get('qwe');
  const hasFields = qwe && companyId;

  const requestOtpMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch(urls.getOtp, { method: 'POST', body: JSON.stringify({ id: qwe }) });
      if (!response.ok) {
        throw new Error('Failed to request OTP');
      }
      return await response.json();
    },
  });

  const verifyOtpMutation = useMutation({
    mutationFn: async (otp: string) => {
      const response = await fetch(
        `${urls.verifyOtp}?includeOptions=true&secretCode=${otp}&qwe=${qwe}&company_id=${companyId}`
      );
      if (!response.ok) {
        throw new Error('Error validating OTP.');
      }
      return await response.json();
    },
    onSuccess: () => {
      signIn();
      navigate({ to: '/home' });
    },
  });

  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div css={{ maxWidth: 450 }}>
        <div css={{ marginBottom: theme.spacing(3) }}>
          <TextField
            name='phone-number'
            label='6 digit code'
            {...fieldProps}
            css={{ marginBottom: theme.spacing(1) }}
          />
          {verifyOtpMutation.isError || requestOtpMutation.isError ? (
            <Text color='error'>Encountered an error</Text>
          ) : (
            <Text size='medium' color='light'>
              Enter the code you received via SMS
            </Text>
          )}
        </div>
        <FormRow>
          <Button
            onClick={() => {
              verifyOtpMutation.reset();
              requestOtpMutation.mutate();
            }}
            disabled={!hasFields || requestOtpMutation.isSuccess}
          >
            Request OTP
          </Button>
          <Button
            loading={verifyOtpMutation.isLoading}
            variant='secondary'
            disabled={fieldProps.value.length !== 6}
            onClick={() => {
              verifyOtpMutation.mutate(fieldProps.value);
              requestOtpMutation.reset();
            }}
          >
            Validate code
          </Button>
        </FormRow>
      </div>
    </div>
  );
}
