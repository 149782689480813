import {
  ComponentType,
  Dispatch,
  forwardRef,
  KeyboardEventHandler,
  memo,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
  type ComponentProps,
} from 'react';
import { SerializedStyles } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useThemeValues } from '../../../../hooks';
import { Chip } from '../../../chip';
import { TagChipStylesProps } from '../../../chip/chip.styles';
import { TextLink } from '../../../text-link';
import type { BaseInputProps, InputProps } from '../../atoms';
import { BasicFormFieldProps, ExtraFieldProps, FieldLayoutWithAction } from '../../layouts';

type ChipFieldProps = BasicFormFieldProps<'multiselect'> &
  Omit<ExtraFieldProps, 'clearable'> &
  BaseInputProps & {
    startAdornment?: ReactNode;
    fieldComponentProps?: Record<string, unknown> & {
      onInputChange?: (value: string) => void;
      clearable?: boolean;
      allowInput?: boolean;
    };
  };

export const ChipField = forwardRef<HTMLDivElement, ChipFieldProps & Props>((props, ref) => {
  return (
    <FieldLayoutWithAction
      data-focusable
      //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
      field={ChipInput as ComponentProps<typeof FieldLayoutWithAction>['field']}
      css={{ height: 'auto', padding: theme.spacing(1, 2) }}
      {...props}
      ref={ref}
    />
  );
});

ChipField.displayName = 'ChipField';

type ValidateContext = {
  tags: string[];
};

export type AddTagContext = {
  tags: string[];
  validateInput: (value: string, context: ValidateContext) => boolean;
  fieldOnChange: (value: string) => void;
  setTags: Dispatch<SetStateAction<string[]>>;
  setInputValue: Dispatch<SetStateAction<string>>;
  onInputChange?: (value: string) => void;
};

export type ClearTagsContext = {
  tags: string[];
  fieldOnChange: (value: string | []) => void;
  setTags: Dispatch<SetStateAction<string[]>>;
};

type Props = BaseInputProps &
  Omit<InputProps<'text'>, 'value'> & {
    containerCss?: SerializedStyles;
    startAdornment?: ReactNode;
    value: string[];
    limit?: number;
    validateFn?: (value: string, context: ValidateContext) => boolean;
    onRemoveTag?: (tag: string) => void;
    addTagFn?: (value: string, context: AddTagContext) => void;
    clearTagsFn?: (context: ClearTagsContext) => void;
    onInputChange?: (value: string) => void;
    tags?: string[];
    setTags?: Dispatch<SetStateAction<string[]>>;
    inputValue?: string;
    setInputValue?: Dispatch<SetStateAction<string>>;
    handleCustomBlur?: () => void;
    ChipComponent?: ComponentType<React.PropsWithChildren<{ onClick?: () => void; children?: string }>>;
  };

const defaultValidateInput = (value: string, context: ValidateContext) => {
  const { tags } = context;
  return !tags.includes(value);
};

const defaultAddTag = (
  value: string,
  { tags, validateInput, fieldOnChange, setTags, setInputValue, onInputChange }: AddTagContext
) => {
  if (value !== '' && validateInput(value, { tags })) {
    fieldOnChange(value);
    setTags((tags) => [...tags, value]);
    setInputValue('');
    onInputChange?.('');
  }
};

const defaultClearTags = ({ fieldOnChange, setTags }: ClearTagsContext) => {
  fieldOnChange([]);
  setTags([]);
};

export const ChipInput = forwardRef<
  HTMLInputElement,
  Props & {
    inputValue?: string;
    setInputValue?: Dispatch<SetStateAction<string>>;
    tags?: string[];
    setTags?: Dispatch<SetStateAction<string[]>>;
    clearable?: boolean;
    allowInput?: boolean;
  }
>((props, ref) => {
  /**
   * If tags and setTags are not provided, we need to manage the state internally.
   */
  const [internalTags, setInternalTags] = useState<string[]>(props.tags ?? props.value ?? []);
  const [internalInputValue, setInternalInputValue] = useState('');

  const { tags, setTags, inputValue, setInputValue } = props;

  if (!!tags !== !!setTags) {
    throw new Error('tags and setTags must be used together');
  }

  return (
    <ChipInputBase
      {...props}
      tags={tags ?? internalTags}
      setTags={setTags ?? setInternalTags}
      inputValue={inputValue ?? internalInputValue}
      setInputValue={setInputValue ?? setInternalInputValue}
      ref={ref}
    />
  );
});

ChipInput.displayName = 'ChipInput';

export const TagInput = memo(
  forwardRef<
    HTMLInputElement,
    TagInputProps & {
      inputValue?: string;
      setInputValue?: Dispatch<SetStateAction<string>>;
      tags?: TagType[];
      setTags?: Dispatch<SetStateAction<TagType[]>>;
      isClearable?: boolean;
      isRemovable?: boolean;
      allowInput?: boolean;
    }
  >((props, ref) => {
    /**
     * This component manages the tags with type TagType internally.
     */
    const [internalTags, setInternalTags] = useState<TagType[]>(props.tags ?? []);
    const [internalInputValue, setInternalInputValue] = useState('');

    useEffect(() => {
      setInternalTags(props.tags ?? []);
    }, [props.tags]);

    useEffect(() => {
      props.onChange({ name: props.name, value: [] });
      if (internalTags.length > 0) {
        props.onChange({ name: props.name, value: internalTags.map((tag) => tag.value) });
      }
      if (props.setTags) {
        props.setTags(internalTags);
      }
    }, [internalTags]);

    return (
      <TagInputBase
        {...props}
        tags={internalTags}
        setTags={setInternalTags}
        inputValue={internalInputValue}
        setInputValue={setInternalInputValue}
        ref={ref}
      />
    );
  })
);

TagInput.displayName = 'TagInput';

export const ChipInputBase = forwardRef<
  HTMLInputElement,
  Props & {
    tags: string[];
    setTags: Dispatch<SetStateAction<string[]>>;
    inputValue: string;
    setInputValue: Dispatch<SetStateAction<string>>;
    onRemoveTag?: (tag: string) => void;
    clearable?: boolean;
    handleCustomBlur?: () => void;
    allowInput?: boolean;
  }
>(
  (
    {
      className,
      limit,
      clearable = true,
      validateFn,
      addTagFn,
      clearTagsFn,
      containerCss,
      onChange,
      onInputChange,
      onBlur,
      handleCustomBlur,
      onKeyUp,
      onKeyDown,
      tags,
      setTags,
      inputValue,
      setInputValue,
      onRemoveTag,
      ChipComponent,
      allowInput = true,
      ...rest
    },
    ref
  ) => {
    const theme = useThemeValues();
    const Component = ChipComponent ?? Chip.Removable;

    const validateInput = validateFn ?? defaultValidateInput;
    const addTag = addTagFn ?? defaultAddTag;
    const clearTags = clearTagsFn ?? defaultClearTags;

    const keyUpHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
      onKeyUp?.(e);
      if (e.key === 'Enter') {
        const shouldAddTag =
          e.currentTarget.value !== '' &&
          validateInput(e.currentTarget.value, { tags }) &&
          (limit === undefined || limit > tags.length);

        if (shouldAddTag) {
          addTag(e.currentTarget.value, {
            tags,
            validateInput,
            fieldOnChange: (value) => onChange({ name: rest.name, value }),
            setTags,
            setInputValue,
            onInputChange,
          });
        }
      }
    };

    const keyDownHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
      onKeyDown?.(e);
      if (e.key === 'Backspace') {
        /**
         * This behavior needs to be a keydown behavior because the input value will not have changed yet
         */
        const shouldRemoveTag = tags.length > 0 && inputValue === '';
        if (shouldRemoveTag) {
          const tag = tags.pop();
          if (tag) {
            onChange({ name: rest.name, value: tag });
            onRemoveTag?.(tag);
          }
          /**
           * Since we've popped the last tag, we can just set the `tags` because the array has been modified in place.
           */
          setTags([...tags]);
        }
      }
    };

    const chipOnClickHandler = (tag: string) => {
      setTags((tags) => tags.filter((t) => t !== tag));
      onChange({ name: rest.name, value: tag });
      onRemoveTag?.(tag);
    };

    const handleBlur =
      handleCustomBlur ??
      (() => {
        /**
         * Since the input here is independent of the field props being passed in, the field label is not affected by the input value.
         *
         * We need to clear out input value on blur so that the input value is gone when the label falls into the field.
         */
        onInputChange?.('');
        setInputValue('');
        onBlur();
      });

    return (
      <div
        style={{
          width: '100%',
          display: 'grid',
          gap: theme.spacing(1),
          gridTemplateColumns: '1fr auto',
        }}
        tabIndex={0}
        className={className}
      >
        <div
          style={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1) }}
          {...rest}
          {...(!allowInput && { ref: ref })}
        >
          {tags.map((tag, ix) => (
            <Component key={`${ix}-${tag}`} onClick={() => chipOnClickHandler(tag)}>
              {tag}
            </Component>
          ))}
          {allowInput && (
            <input
              style={{ flexGrow: 1, flexBasis: 'min-content' }}
              {...rest}
              value={inputValue}
              onChange={(e) => {
                onInputChange?.(e.currentTarget.value);
                setInputValue(e.currentTarget.value);
              }}
              onBlur={handleBlur}
              onKeyUp={keyUpHandler}
              onKeyDown={keyDownHandler}
              type='text'
              ref={ref}
            />
          )}
        </div>
        {clearable && (
          <TextLink
            onClick={() => clearTags({ fieldOnChange: (value) => onChange({ name: rest.name, value }), setTags, tags })}
          >
            Clear
          </TextLink>
        )}
      </div>
    );
  }
);

ChipInputBase.displayName = 'ChipInputBase';

export type TagType = { label: string; value: string; color: TagChipStylesProps['color'] };

type TagInputProps = BaseInputProps &
  Omit<InputProps<'text'>, 'value'> & {
    containerCss?: SerializedStyles;
    startAdornment?: ReactNode;
    value: string[];
    limit?: number;
    validateFn?: (value: string, context: ValidateContext) => boolean;
    onRemoveTag?: (tag: TagType) => void;
    addTagFn?: (value: string, context: AddTagContext) => void;
    clearTagsFn?: (context: ClearTagsContext) => void;
    onInputChange?: (value: string) => void;
    tags: TagType[];
    setTags: Dispatch<SetStateAction<TagType[]>>;
    inputValue?: string;
    setInputValue?: Dispatch<SetStateAction<string>>;
    handleCustomBlur?: () => void;
  };

export const TagInputBase = forwardRef<
  HTMLInputElement,
  TagInputProps & {
    inputValue: string;
    setInputValue: Dispatch<SetStateAction<string>>;
    onRemoveTag?: (tag: TagType) => void;
    isClearable?: boolean;
    isRemovable?: boolean;
    handleCustomBlur?: () => void;
    allowInput?: boolean;
  }
>(
  (
    {
      className,
      limit,
      isClearable,
      isRemovable,
      validateFn,
      addTagFn,
      clearTagsFn,
      containerCss,
      onInputChange,
      handleCustomBlur,
      onKeyUp,
      onKeyDown,
      tags,
      setTags,
      inputValue,
      setInputValue,
      onRemoveTag,
      allowInput,
      ...rest
    },
    ref
  ) => {
    const theme = useThemeValues();

    const canAdd = (value: string) => {
      return !tags.some((tag) => tag.value === value);
    };
    const addTag = (tags: TagType[], label: string) => {
      setTags([...tags, { label, value: label, color: 'blue' }]);
      setInputValue('');
      onInputChange?.('');
    };
    const clearTags = () => {
      setTags([]);
    };

    const keyUpHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key === 'Enter') {
        const shouldAddTag =
          e.currentTarget.value !== '' && canAdd(e.currentTarget.value) && (limit === undefined || limit > tags.length);
        if (shouldAddTag) {
          addTag(tags, e.currentTarget.value);
        }
      }
    };

    const keyDownHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key === 'Backspace') {
        /**
         * This behavior needs to be a keydown behavior because the input value will not have changed yet
         */
        const shouldRemoveTag = tags.length > 0 && inputValue === '';
        if (shouldRemoveTag) {
          const tag = tags.pop();
          if (tag) {
            onRemoveTag?.(tag);
          }
          /**
           * Since we've popped the last tag, we can just set the `tags` because the array has been modified in place.
           */
          setTags([...tags]);
        }
      }
    };

    const onRemoveHandler = (tag: TagType) => {
      setTags(tags.filter((t) => t.value !== tag.value));
    };

    const handleBlur =
      handleCustomBlur ??
      (() => {
        /**
         * Since the input here is independent of the field props being passed in, the field label is not affected by the input value.
         *
         * We need to clear out input value on blur so that the input value is gone when the label falls into the field.
         */
        onInputChange?.('');
        setInputValue('');
        rest.onBlur();
      });

    return (
      <div
        style={{
          width: '100%',
          display: 'grid',
          gap: theme.spacing(1),
          gridTemplateColumns: '1fr auto',
          height: '100%',
        }}
        className={className}
      >
        <div
          style={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1) }}
          /**
           * Pass event handlers
           */
          {...rest}
          {...(!allowInput && { ref: ref })}
        >
          {tags.map((tag, ix) => (
            <Chip.Tag
              isRemovable={isRemovable}
              color={tag.color}
              key={`${ix}-${tag}`}
              onRemoveClick={() => onRemoveHandler(tag)}
            >
              {tag.label}
            </Chip.Tag>
          ))}
          {allowInput && (
            <input
              style={{ flexGrow: 1, flexBasis: 'min-content' }}
              value={inputValue}
              onChange={(e) => {
                onInputChange?.(e.currentTarget.value);
                setInputValue(e.currentTarget.value);
              }}
              onBlur={handleBlur}
              onKeyUp={keyUpHandler}
              onKeyDown={keyDownHandler}
              type='text'
              ref={ref}
            />
          )}
        </div>
        {isClearable && <TextLink onClick={() => clearTags()}>Clear</TextLink>}
      </div>
    );
  }
);

TagInputBase.displayName = 'TagInputBase';
