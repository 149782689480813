import {
  useState,
  useRef,
  useCallback,
  Children,
  isValidElement,
  useEffect,
  ComponentProps,
  forwardRef,
  ReactNode,
  createContext,
  useContext,
  useId,
  useMemo,
  ReactElement,
} from 'react';
import {
  useListItem,
  flip,
  size,
  Placement,
  useFloatingNodeId,
  useFloating,
  autoUpdate,
  offset,
  useInteractions,
  useClick,
  useRole,
  useDismiss,
  useListNavigation,
  FloatingNode,
  FloatingPortal,
  FloatingFocusManager,
} from '@floating-ui/react';
import { onlyText } from 'react-children-utilities';
import { Icon, IconName } from '@frontend/icons';
import type { WeaveTheme as OriginalTheme } from '@frontend/theme-original';
import type { WeaveTheme } from '@frontend/theme';
import { useThemeValues } from '../../../../hooks';
import { styles } from '../../../../styles';
import { Button } from '../../../button';
import { Chip } from '../../../chip';
import { Text } from '../../../text';
import { TextLink } from '../../../text-link';
import { Checkbox } from '../../atoms';
import { ListFieldProps, useFormField } from '../../hooks';
import { BasicFormFieldProps, FieldLayout, FieldLayoutWithAction } from '../../layouts';
import { DropdownIcon } from '../dropdown';
import { Menu } from './atoms';
import { MAX_FLOATING_HEIGHT, MAX_FLOATING_WIDTH } from './const';

export type CheckListMenuProps = BasicFormFieldProps<'checklist'> & {
  children: ReactNode;
  placement?: Placement;
};

export const ChecklistMenuField = ({
  children,
  maxAllowed,
  placeholder = 'Select one',
  placement,
  sortLabelsBySelection = false,
  ...rest
}: CheckListMenuProps & {
  sortLabelsBySelection?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const labelsRef = useRef<Array<string | null>>([]);
  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const theme = useThemeValues();

  const nodeId = useFloatingNodeId();

  const { refs, context, floatingStyles } = useFloating<HTMLInputElement>({
    nodeId,
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: (nextOpen) => {
      setIsOpen(nextOpen);
      if (!nextOpen) {
        setActiveIndex(null);
      }
    },
    middleware: [
      offset(placement === 'top' ? 12 : 3),
      flip(),
      size({
        apply({ elements, rects }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    placement,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'listbox' }),
    useDismiss(context),
    useListNavigation(context, {
      activeIndex,
      listRef: listItemsRef,
      loop: true,
      onNavigate: setActiveIndex,
      openOnArrowKeyDown: true,
      selectedIndex,
      /**
       * Using virtual means that focus will stay on the floating element (Menu) even as the user navigates the list - the focus never truly enters the list items.
       * This will change the user experience with keyboard controls like Enter and Space.
       */
      // virtual: true,
    }),
  ]);

  const handleSelect = useCallback(
    (index: number | null) => {
      setSelectedIndex(index);
      if (index !== null) {
        /**
         * Check for item label before selecting it.
         * This will be a problem if multiple items have the same label.
         */
        const item = Children.toArray(children).find(
          (child) => isValidElement(child) && onlyText(child.props?.children) === labelsRef.current[index]
        );

        if (item && isValidElement(item) && !item.props.disabled) {
          rest.onChange({ name: rest.name, value: item.props?.value });
        }
      }
    },
    [children, rest.onChange, rest.name]
  );

  const clearSelection = () => {
    rest.onChange({ name: rest.name, value: [] });
  };

  const selectAll = useCallback(
    (filterFn: (child: ReactElement) => boolean) => {
      const items = Children.toArray(children)
        .filter((child) => isValidElement(child) && !child.props.disabled && filterFn(child))
        .map((child) => (child as ReactElement).props?.value);

      /**
       * Because the multiselect field has special `onChange` logic, this trick allows us to set
       * exactly what we want as the value of the field.
       */
      rest.onChange({ name: rest.name, value: [] });
      rest.onChange({ name: rest.name, value: items });
    },
    [children]
  );

  useEffect(() => {
    /**
     * This effect is used to update the **label** in the dropdown trigger when the field **value** changes.
     */
    if (rest.value) {
      const labels = sortLabels({ sortLabelsBySelection, values: rest.value, children });
      setSelectedLabels(labels);
    }
  }, [rest.value]);

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      selectedItems: rest.value,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, getItemProps, rest.value, selectedIndex, handleSelect]
  );

  return (
    <FieldLayout
      field={Field as ComponentProps<typeof FieldLayoutWithAction>['field']}
      fieldComponentProps={{
        options: children,
        ref: refs.setReference,
      }}
      endAdornment={<DropdownIcon active={isOpen} />}
      placeholder={placeholder}
      {...getReferenceProps({})}
      {...rest}
      hasPadding={false}
      /**
       * We derive props below for the field instead of relying on the current state
       */
      value={selectedLabels.join(', ')}
      active={activeIndex !== null || rest.active}
      onChange={() => {}}
    >
      <FloatingNode id={nodeId}>
        <SelectContext.Provider value={selectContext}>
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager context={context} initialFocus={0}>
                <div
                  style={{
                    ...floatingStyles,
                    boxShadow: theme.shadows.heavy,
                    borderRadius: theme.borderRadius.medium,
                    backgroundColor: theme.colors.white,
                    zIndex: theme.zIndex.popover,
                    overflow: 'hidden',
                    display: 'grid',
                    gridTemplateRows: 'minmax(0, 1fr)',
                    maxHeight: MAX_FLOATING_HEIGHT,
                    maxWidth: MAX_FLOATING_WIDTH,
                  }}
                  ref={refs.setFloating}
                >
                  <Menu
                    floatingProps={getFloatingProps({
                      onKeyDown: (e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleSelect(activeIndex);
                        }
                      },
                    })}
                    ref={refs.setFloating}
                    refs={{ listItemRefs: listItemsRef, labelsRef }}
                    clearSelection={clearSelection}
                    selectAll={selectAll}
                  >
                    {children}
                  </Menu>
                </div>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </SelectContext.Provider>
      </FloatingNode>
    </FieldLayout>
  );
};

const sortLabels = ({
  sortLabelsBySelection,
  values,
  children,
}: {
  sortLabelsBySelection: boolean;
  children: ReactNode;
  values: string[];
}) => {
  if (sortLabelsBySelection) {
    /**
     * Loops through selected values first
     */
    const items = Object.fromEntries(
      Children.toArray(children).map((child) =>
        isValidElement(child) ? [child.props.value, onlyText(child.props.children)] : []
      )
    );
    return values.map((value) => {
      return items[value];
    });
  } else {
    /**
     * Loops through options (children) first
     */
    return Children.toArray(children)
      .filter((child) => isValidElement(child) && values.includes(child.props?.value))
      .map(onlyText);
  }
};

export const DropdownFilterChipField = ({
  children,
  maxAllowed,
  placement = 'bottom-start',
  icon,
  label,
  buttonLabel,
  sortLabelsBySelection = false,
  getCustomLabel,
  onApply,
  onDismiss,
  className,
  showClearAll = true,
  ...rest
}: Omit<CheckListMenuProps, 'placeholder'> & {
  icon?: IconName;
  sortLabelsBySelection?: boolean;
  buttonLabel?: string;
  /**
   * Use this to generate a custom label based on the selected items.
   * @param labels list of selected item labels
   * @returns custom label to display in the dropdown trigger
   */
  getCustomLabel?: (labels: string[]) => string;
  onApply?: ({ value, close }: { value: string[]; close: () => void }) => void;
  onDismiss?: ({ value, initialValue }: { value: string[]; initialValue: string[] }) => void;
  showClearAll?: boolean;
  trackingIdBase?: string;
}) => {
  const theme = useThemeValues();
  const internalField = useFormField({ type: 'checklist', value: rest.value }, [rest.value]);

  return (
    <div css={{ display: 'flex', gap: theme.spacing(2) }}>
      <DropdownFilterChip
        icon={icon}
        label={label}
        buttonLabel={buttonLabel}
        placement={placement}
        sortLabelsBySelection={sortLabelsBySelection}
        getCustomLabel={getCustomLabel}
        onApply={onApply}
        onDismiss={({ value, initialValue }) => {
          // Reset internal field to what it was before the user opened the dropdown
          internalField.onChange({ name: rest.name, value: [] });
          internalField.onChange({ name: rest.name, value: initialValue });
          onDismiss?.({ value, initialValue });
        }}
        internalField={internalField}
        className={className}
        {...rest}
      >
        {children}
      </DropdownFilterChip>
      <ClearAllButton
        onClick={() => {
          internalField.onChange({ name: 'location-filter', value: [] });
          rest.onChange({ name: 'location-filter', value: [] });
        }}
        // Only show if applied value is not empty
        shouldShow={showClearAll && rest.value.length > 0}
        trackingId={`${rest.trackingIdBase}-clear-all`}
      />
    </div>
  );
};

/**
 * This component uses an internalField to track changes, and then updates the field value passed in when the user clicks the Apply button.
 */
const DropdownFilterChip = ({
  children,
  maxAllowed,
  placement = 'bottom-start',
  icon,
  label,
  buttonLabel,
  sortLabelsBySelection = false,
  getCustomLabel,
  onApply,
  internalField,
  className,
  onDismiss,
  trackingIdBase,
  ...rest
}: Omit<CheckListMenuProps, 'placeholder'> & {
  internalField: ListFieldProps;
  icon?: IconName;
  sortLabelsBySelection?: boolean;
  buttonLabel?: string;
  /**
   * Use this to generate a custom label based on the selected items.
   * @param labels list of selected item labels
   * @returns custom label to display in the dropdown trigger
   */
  getCustomLabel?: (labels: string[]) => string;
  onApply?: ({ value, close }: { value: string[]; close: () => void }) => void;
  onDismiss?: ({ value, initialValue }: { value: string[]; initialValue: string[] }) => void;
  trackingIdBase?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const labelsRef = useRef<Array<string | null>>([]);
  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const theme = useThemeValues();

  const nodeId = useFloatingNodeId();

  /**
   * We show an Apply button only if the onApply prop is passed in.
   * In that case, we use an internal field to track changes, and then update the field value passed in when the user clicks the Apply button.
   * If the onApply prop is not passed in, we use the field prop (passed in through rest) directly.
   */
  const field = onApply ? internalField : rest;

  const { refs, context, floatingStyles } = useFloating<HTMLInputElement>({
    nodeId,
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: (nextOpen, _e, reason) => {
      setIsOpen(nextOpen);
      if (!nextOpen) {
        setActiveIndex(null);
        if (reason === 'escape-key' || reason === 'outside-press') {
          onDismiss?.({ value: field.value, initialValue: rest.value });
        }
      }
    },
    middleware: [offset(3), flip()],
    placement,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'listbox' }),
    useDismiss(context),
    useListNavigation(context, {
      activeIndex,
      listRef: listItemsRef,
      loop: true,
      onNavigate: setActiveIndex,
      openOnArrowKeyDown: true,
      selectedIndex,
      /**
       * Using virtual means that focus will stay on the floating element (Menu) even as the user navigates the list - the focus never truly enters the list items.
       * This will change the user experience with keyboard controls like Enter and Space.
       */
      // virtual: true,
    }),
  ]);

  const handleSelect = useCallback(
    (index: number | null) => {
      setSelectedIndex(index);
      if (index !== null) {
        /**
         * Check for item label before selecting it.
         * This will be a problem if multiple items have the same label.
         */
        const item = Children.toArray(children).find(
          (child) => isValidElement(child) && onlyText(child.props?.children) === labelsRef.current[index]
        );

        if (item && isValidElement(item) && !item.props.disabled) {
          field.onChange({ name: rest.name, value: item.props?.value });
        }
      }
    },
    [children, field.onChange, rest.name]
  );

  const clearSelection = () => {
    field.onChange({ name: rest.name, value: [] });
  };

  const selectAll = useCallback(
    (filterFn: (child: ReactElement) => boolean) => {
      const items = Children.toArray(children)
        .filter((child) => isValidElement(child) && !child.props.disabled && filterFn(child))
        .map((child) => (child as ReactElement).props?.value);

      /**
       * Because the multiselect field has special `onChange` logic, this trick allows us to set
       * exactly what we want as the value of the field.
       */
      field.onChange({ name: rest.name, value: [] });
      field.onChange({ name: rest.name, value: items });
    },
    [children]
  );

  useEffect(() => {
    /**
     * This effect is used to update the **label** in the dropdown trigger when the field **value** changes.
     */
    if (rest.value) {
      const labels = sortLabels({ sortLabelsBySelection, values: rest.value, children });

      setSelectedLabels(labels);
    }
  }, [rest.value]);

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      selectedItems: field.value,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, getItemProps, field.value, selectedIndex, handleSelect]
  );

  return (
    <>
      <Chip.DropdownFilter
        {...getReferenceProps()}
        leftElement={<Icon name={icon ?? 'location'} size={16} />}
        ref={refs.setReference}
        isOpen={isOpen}
        css={{ maxWidth: 240 }}
        className={className}
        trackingId={trackingIdBase}
      >
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <Text size='large' css={styles.truncate}>
            {getCustomLabel && selectedLabels.length > 0 ? getCustomLabel(selectedLabels) : label}
          </Text>
        </div>
      </Chip.DropdownFilter>
      <FloatingNode id={nodeId}>
        <SelectContext.Provider value={selectContext}>
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager context={context} initialFocus={0}>
                <div
                  style={{
                    ...floatingStyles,
                    boxShadow: theme.shadows.heavy,
                    borderRadius: theme.borderRadius.medium,
                    backgroundColor: theme.colors.white,
                    zIndex: theme.zIndex.popover,
                    overflow: 'hidden',

                    display: 'grid',
                    gridTemplateRows: 'minmax(0, 1fr) auto',
                    maxHeight: MAX_FLOATING_HEIGHT,
                    maxWidth: MAX_FLOATING_WIDTH,
                  }}
                  ref={refs.setFloating}
                >
                  <Menu
                    floatingProps={getFloatingProps({
                      onKeyDown: (e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleSelect(activeIndex);
                        }
                      },
                    })}
                    refs={{ listItemRefs: listItemsRef, labelsRef }}
                    clearSelection={clearSelection}
                    selectAll={selectAll}
                    trackingIdBase={trackingIdBase}
                  >
                    {children}
                  </Menu>
                  {onApply && (
                    <div style={{ padding: theme.spacing(1, 2, 2) }}>
                      <Button
                        onClick={() => {
                          rest.onChange({ name: rest.name, value: [] });
                          rest.onChange({ name: rest.name, value: field.value });

                          onApply({ value: field.value, close: () => setIsOpen(false) });
                        }}
                        trackingId={`${trackingIdBase}-apply-button`}
                      >
                        {buttonLabel ?? 'Apply'}
                      </Button>
                    </div>
                  )}
                </div>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </SelectContext.Provider>
      </FloatingNode>
    </>
  );
};

const Field = forwardRef<HTMLInputElement>((props, ref) => {
  const theme = useThemeValues();
  return (
    <input
      style={{ cursor: 'default', padding: theme.spacing(0, 4, 0, 2), height: '100%', margin: 0 }}
      css={styles.truncate}
      readOnly
      ref={ref}
      {...props}
    />
  );
});

Field.displayName = 'Field';

type SelectProps = {
  activeIndex: number | null;
  selectedIndex: number | null;
  selectedItems: string[];
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number | null) => void;
};

export const SelectContext = createContext({} as SelectProps);

const Option = ({
  children,
  value,
  disabled,
  trackingId,
}: {
  children: ReactNode;
  value: string;
  disabled?: boolean;
  trackingId?: string;
}) => {
  const { activeIndex, selectedItems, getItemProps, handleSelect } = useContext(SelectContext);
  const theme = useThemeValues();
  const id = useId();

  const { ref, index } = useListItem();
  const isActive = activeIndex === index;
  const isSelected = selectedItems.includes(value);

  return (
    <li
      aria-disabled={disabled}
      ref={ref}
      id={id}
      role='option'
      aria-selected={isActive && isSelected}
      tabIndex={-1}
      css={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        background: isActive ? (theme as WeaveTheme).colors.neutral5 ?? (theme as OriginalTheme).colors.gray200 : '',
        fontWeight: isSelected ? 'bold' : '',
        listStyle: 'none',
        display: 'flex',
        gap: theme.spacing(1),
        padding: theme.spacing(1, 2),
        outline: 'none',
        color: disabled
          ? theme.font.colors.disabled
          : (theme as WeaveTheme).colors.neutral90 ?? (theme as OriginalTheme).colors.gray600,
      }}
      {...getItemProps({
        onClick: () => {
          handleSelect(index);
        },
      })}
    >
      <Checkbox
        disabled={disabled}
        name='test'
        id={`checkbox-${id}`}
        aria-invalid={false}
        value={isSelected}
        onBlur={() => {}}
        onChange={() => {}}
        onFocus={() => {}}
        active={isActive}
        error={false}
        tabIndex={-1}
        trackingId={trackingId}
      />
      {children}
    </li>
  );
};

const ClearAllButton = ({
  label,
  onClick,
  shouldShow,
  trackingId,
}: {
  label?: string;
  onClick: () => void;
  shouldShow?: boolean;
  trackingId?: string;
}) => {
  return (
    <TextLink
      hidden={!shouldShow}
      weight='bold'
      onKeyDown={(e) => {
        if (e.key === ' ') {
          e.preventDefault();
        }
      }}
      onClick={onClick}
      trackingId={trackingId}
    >
      {label ?? 'Clear All'}
    </TextLink>
  );
};

ChecklistMenuField.Option = Option;
DropdownFilterChipField.Option = Option;
